export const FRAMEWORK = 'fabric';
export const NAMESPACE = 'registration';
export const APPLICATION_NAME = `${FRAMEWORK}-${NAMESPACE}`;
export const GOOGLE_PLACES_API_KEY = 'AIzaSyCFONsFGm-eog0OcPoOq0Luu1Xxs33szv0';
export const GOOGLE_ADDRESS_VALIDATION_API_KEY =
  'AIzaSyCWmNQLPtSknDt6d_ZXg4f1osOcjjo2SOc';

export const HEADER_NAMES = Object.freeze({
  CASUMO_BRAND_ID: 'X-Brand',
});

export const REGISTRATION_FLOW_ENDPOINTS = Object.freeze({
  SWEDEN_REGISTRATION: '/sweden/register',
  MGA_GRA_REGISTRATION: '/mga/gra/register',
  UKGC_REGISTRATION: '/ukgc/register',
  /* NEW_MARKET */
});

export const REGISTRATION_FLOW_NAMES = Object.freeze({
  SWEDEN: 'Sweden',
  MGA_GRA: 'MGA GRA registration',
  UKGC: 'UKGC'
  /* NEW_MARKET */
});

export const REGISTRATION_FLOW_STEPS = Object.freeze({
  WELCOME_OFFER_STEP: 'welcome_offer_step',
  DEPOSIT_STEP: 'deposit_step',
  LIMITS_STEP: 'limits_step',
  ACCOUNT_DETAILS_STEP: 'account_details_step',
  NATIONALITY_STEP: 'nationality_step',
  MARKETING_CONSENT_STEP: 'marketing_consent_step',
  COUNTRY_RESIDENCE_STEP: 'country_residence_step',
  FULL_NAME_NATIONALITY: 'fullname_nationality',
  BIRTH_DETAILS_STEP: 'birth_details_step',
  ADDRESS: 'address_step',
  /* NEW_MARKET */
  RESIDENCY_ADDRESS: 'residency_address',
});

export const REGISTRATION_FLOW_STEP_NAMES = Object.freeze({
  MGA_GRA_NATIONALITY_STEP: 'MGA-GRA nationality step',
  UKGC_NATIONALITY_STEP: "UKGC - nationality step"
});

export const HEADER_TITLES = Object.freeze({
  WELCOME_OFFER: 'Select a bonus',
  DEPOSIT_AMOUNT: 'Add funds',
  LIMITS: 'Set deposit limits',
  EMAIL_AND_PHONE: 'Account details',
  NATIONALITY: 'Account details',
  MARKETING_CONSENT: 'Complete account',
});

export const TERMS_AND_CONDITIONS_TYPES = Object.freeze({
  PRIVACY_POLICY: 'privacy-policy',
  COOKIES_POLICY: "cookie-policy",
  BONUS_TERMS: 'bonus-terms',
  GENERAL_TERMS: 'general-terms-and-conditions',
  DEPOSIT_BONUS_TERMS: 'deposit-bonus-t&cs',
  SPORTS_GENERAL_TERMS_AND_CONDITIONS: 'sports-general-terms-and-conditions',

});

export const JURISDICTION = Object.freeze({
  SGA: 'SGA',
  MGA: 'MGA',
  GRA: 'GRA',
  UKGC: 'UKGC',
});

export const MARKETS = Object.freeze({
  DE: 'de',
  EN: 'en',
  ENCA: 'en-ca',
  ENGB: 'en-gb',
  ENIN: 'en-in',
  ENNZ: 'en-nz',
  ES: 'es',
  IE: 'ie',
  JP: 'ja',
  NO: 'no',
  ROW: 'row',
  SV: 'sv',
  ROG: 'rog',
  ON: 'on',
  FI: 'fi',
  /* NEW_MARKET */
});


export const CASUMO_REGISTRATION_ENABLED_MARKETS = Object.freeze([
  "en",
  "ie",
  "fi",
  "row",
  "rog",
  "en-ca",
  "no",
  "en-nz",
  "en-gb",
  "sv"
]);
export const CASUMO_TEST_REGISTRATION_ENABLED_MARKETS = Object.freeze([
  "en",
  "ie",
  "fi",
  "row",
  "rog",
  "en-ca",
  "no",
  "en-nz",
  "en-gb",
]);


export const MGA_MARKETS: string[] = [
  MARKETS.EN,
  MARKETS.ROW,
  MARKETS.ROG,
];
export const GRA_MARKETS: string[] = [
  MARKETS.FI,
  MARKETS.ENCA,
  MARKETS.ENIN,
  MARKETS.JP,
  MARKETS.NO,
  MARKETS.IE,
  MARKETS.ENNZ,
];
export const AGCO_MARKETS: string[] = [MARKETS.ON];
export const UKGC_MARKETS: string[] = [MARKETS.ENGB];
export const DGOJ_MARKETS: string[] = [MARKETS.ES];
export const SGA_MARKETS: string[] = [MARKETS.SV];

export const DEFAULT_MARKET = MARKETS.EN;
export const DEFAULT_LANGUAGE = 'sv-SE';

// used to send x-player-market header through casino endpoints.
export const API_LOCALES_BY_MARKET = Object.freeze({
  [MARKETS.DE]: 'de_de',
  [MARKETS.EN]: '___en',
  [MARKETS.ENCA]: 'ca_en',
  [MARKETS.ENGB]: 'gb_en',
  [MARKETS.ENIN]: 'in_en',
  [MARKETS.ENNZ]: 'nz_en',
  [MARKETS.ON]: 'on_en', //Confirmed by Ian and Fabio.
  [MARKETS.ES]: 'es_es',
  [MARKETS.IE]: 'ie_en',
  [MARKETS.JP]: 'jp_ja',
  [MARKETS.NO]: 'no_no',
  [MARKETS.ROW]: '___en',
  [MARKETS.ROG]: '___en',
  [MARKETS.SV]: 'se_sv',
  /* NEW_MARKET */
});

// These errors contain the proper fieldName from directus errors object
export const REGISTRATION_ERRORS = Object.freeze({
  FIELD_IS_REQUIRED: 'field_is_required',
  ADDRESS_NOT_FOUND: 'address_not_found',
  ADDRESS_NOT_MATCHED: 'address_did_not_match_the_records',
  UNKNOWN: 'unknown_error',
  EMAIL_IS_REQUIRED: 'email_required',
  PASSWORD_IS_REQUIRED: 'password_required',
  PHONE_IS_REQUIRED: 'phone_required',
  BIRTH_DATE_IS_REQUIRED: 'birth_date_required',
  BIRTH_PLACE_IS_REQUIRED: 'birth_place_required',
  GENDER_IS_REQUIRED: 'gender_required',
  DUPLICATE_EMAIL: 'duplicate_email',
  DUPLICATE_PHONE: 'duplicate_phone',
  INCORRECT_EMAIL: 'incorrect_email',
  EMAIL_FORBIDDEN: 'email_forbidden',
  INCORRECT_PASSWORD: 'incorrect_password',
  INCORRECT_PHONE_NUMBER: 'incorrect_phone_number',
  INCORRECT_BIRTH_DAY: 'incorrect_birth_day',
  UNDERAGE: 'user_under_age',
  AGE_ERROR: 'user_age_error',
  DEPOSIT_REQUIRED: 'deposit_required',
  DEPOSIT_TOO_LOW: 'deposit_too_low',
  DEPOSIT_TOO_HIGH: 'deposit_too_high',
  LIMIT_TOO_LOW: 'limit_too_low',
  LIMIT_TOO_HIGH: 'limit_too_high',
  LIMIT_IS_REQUIRED: 'limit_required',
  LIMIT_IS_LOWER_THAN_DEPOSIT: 'limit_lower_than_deposit',
  MONTHLY_LIMIT_IS_LOWER_THAN_WEEKLY: 'monthly_limit_lower_than_weekly',
  WEEKLY_LIMIT_IS_LOWER_THAN_DAILY: 'weekly_limit_lower_than_daily',
  NO_WELCOME_OFFER_NOTIFICATION: 'no_welcome_offer_notification',
  NATIONALITY_IS_REQUIRED: 'nationality_is_required',
  INVALID_JURISDICTION_FOR_BRAND: 'invalid_jurisdiction_for_brand',
  COUNTRY_RESTRICTED: 'country_restricted',
  PROVINCE_IN_ADDRESS_REQUIRED: 'province_in_address_required',
  INVALID_OCR_DATA: 'invalid_ocr_data',
  CREDENTIALS_TAKEN: 'credentials_taken',
  UNIQUE_PLAYER: 'unique_player',
  SOCIAL_SECURITY_NUMBER_ALREADY_EXISTS:
    'social_security_number_already_exists',
  SELF_EXCLUDED_WITH_NATIONAL_REGISTRY: 'self_excluded_with_national_registry',
  FIRST_NAME_REQUIRED: 'first_name_required',
  LAST_NAME_REQUIRED: 'last_name_required',
  INVALID_POSTAL_CODE: 'invalid_postalcode',
  COUNTRY_OF_RESIDENCE_IS_REQUIRED: 'country_of_residence_is_required',
  PREDICTIONS_GOOGLE_API_NOT_FOUND: 'predictions_google_api_not_found',
  NETWORK_ERROR: 'network_error',
  KYC_REQUIRED: 'kyc_required'
});

export const REGISTRATION_API_RESPONSES = Object.freeze({
  EMAIL_TAKEN: 'EMAIL_TAKEN',
  INVALID_EMAIL: "INVALID_EMAIL",
  EMAIL_FORBIDDEN: "EMAIL_FORBIDDEN",
  PHONE_NUMBER_ALREADY_EXISTS: 'PHONE_NUMBER_ALREADY_EXISTS',
  VALID: 'VALID',
  INVALID_JURISDICTION_FOR_BRAND: 'INVALID_JURISDICTION_FOR_BRAND',
  COUNTRY_RESTRICTED: 'COUNTRY_RESTRICTED',
  PROVINCE_IN_ADDRESS_REQUIRED: 'PROVINCE_IN_ADDRESS_REQUIRED',
  INVALID_OCR_DATA: 'INVALID_OCR_DATA',
  CREDENTIALS_TAKEN: 'CREDENTIALS_TAKEN',
  UNIQUE_PLAYER: 'UNIQUE_PLAYER',
  SOCIAL_SECURITY_NUMBER_ALREADY_EXISTS:
    'SOCIAL_SECURITY_NUMBER_ALREADY_EXISTS',
  TOO_YOUNG: 'TOO_YOUNG',
  SELF_EXCLUDED_WITH_NATIONAL_REGISTRY: 'SELF_EXCLUDED_WITH_NATIONAL_REGISTRY',
  REGISTRATION_METHOD_COUNTRY_MISMATCH: 'REGISTRATION_METHOD_COUNTRY_MISMATCH',
  CAPTCHA_INVALID_RESPONSE: 'CAPTCHA_INVALID_RESPONSE',
  POSTAL_CODE_FORBIDDEN: 'POSTAL_CODE_FORBIDDEN',
  JURISDICTION_NOT_FOUND: 'JURISDICTION_NOT_FOUND',
  KYC_REQUIRED: "KYC_REQUIRED"
});

export const BE_FE_ERRORS_MAP = Object.freeze({
  [REGISTRATION_API_RESPONSES.EMAIL_TAKEN]: REGISTRATION_ERRORS.DUPLICATE_EMAIL,
  [REGISTRATION_API_RESPONSES.PHONE_NUMBER_ALREADY_EXISTS]:
    REGISTRATION_ERRORS.DUPLICATE_PHONE,
  [REGISTRATION_API_RESPONSES.INVALID_JURISDICTION_FOR_BRAND]:
    REGISTRATION_ERRORS.INVALID_JURISDICTION_FOR_BRAND,
  [REGISTRATION_API_RESPONSES.COUNTRY_RESTRICTED]:
    REGISTRATION_ERRORS.COUNTRY_RESTRICTED,
  [REGISTRATION_API_RESPONSES.PROVINCE_IN_ADDRESS_REQUIRED]:
    REGISTRATION_ERRORS.PROVINCE_IN_ADDRESS_REQUIRED,
  [REGISTRATION_API_RESPONSES.INVALID_OCR_DATA]:
    REGISTRATION_ERRORS.INVALID_OCR_DATA,
  [REGISTRATION_API_RESPONSES.CREDENTIALS_TAKEN]:
    REGISTRATION_ERRORS.CREDENTIALS_TAKEN,
  [REGISTRATION_API_RESPONSES.UNIQUE_PLAYER]: REGISTRATION_ERRORS.UNIQUE_PLAYER,
  [REGISTRATION_API_RESPONSES.SOCIAL_SECURITY_NUMBER_ALREADY_EXISTS]:
    REGISTRATION_ERRORS.SOCIAL_SECURITY_NUMBER_ALREADY_EXISTS,
  [REGISTRATION_API_RESPONSES.TOO_YOUNG]: REGISTRATION_ERRORS.UNDERAGE,
  [REGISTRATION_API_RESPONSES.SELF_EXCLUDED_WITH_NATIONAL_REGISTRY]:
    REGISTRATION_ERRORS.SELF_EXCLUDED_WITH_NATIONAL_REGISTRY,
  [REGISTRATION_API_RESPONSES.KYC_REQUIRED]: REGISTRATION_ERRORS.KYC_REQUIRED,
  [REGISTRATION_API_RESPONSES.INVALID_EMAIL]: REGISTRATION_ERRORS.INCORRECT_EMAIL,
  [REGISTRATION_API_RESPONSES.EMAIL_FORBIDDEN]: REGISTRATION_ERRORS.EMAIL_FORBIDDEN
});

export const ROUTE_NAMES = Object.freeze({
  WELCOME_OFFER: 'WELCOME_OFFER',
  DEPOSIT_AMOUNT: 'DEPOSIT_AMOUNT',
  LIMITS: 'LIMITS',
  EMAIL_AND_PHONE: 'EMAIL_AND_PHONE',
  NATIONALITY: 'NATIONALITY',
  COUNTRY_OF_RESIDENCE: 'COUNTRY_OF_RESIDENCE',
  MARKETING_CONSENT: 'MARKETING_CONSENT',
  ADDRESS: 'ADDRESS',
  BIRTH_DETAILS: 'BIRTH_DETAILS',
});

export const DISPLAYED_CURRENCIES = Object.freeze({
  [MARKETS.SV]: 'kr',
  [MARKETS.EN]: 'eur',
});

export const DEFAULT_MAX_DEPOSIT = 20000000;

export const DEFAULT_MAX_LIMIT = 100000000;

export const TERM_IDS = Object.freeze({
  GENERAL_TERMS: 'general_terms',
});

export const LIMIT_TYPES = Object.freeze({
  MONTHLY: 'MONTHLY',
  WEEKLY: 'WEEKLY',
  DAILY: 'DAILY',
  LIMIT: 'LIMIT',
  TYPE: 'TYPE',
});

export const BIRTH_TYPES = Object.freeze({
  BIRTH_DATE: 'BIRTH_DATE',
  PLACE_OF_BIRTH: 'PLACE_OF_BIRTH',
  SEARCH: 'SEARCH',
});

export const INPUT_TYPES = Object.freeze({
  EMAIL: 'EMAIL',
  PHONE: 'PHONE',
  FIRST_NAME: 'FIRST_NAME',
  LAST_NAME: 'LAST_NAME',
  SEARCH: 'SEARCH',
  PASSWORD: 'PASSWORD',
  TEXT: 'TEXT',
  GENDER: 'GENDER',
});

export const EVENT_TYPES = Object.freeze({
  FULL_NAME_NATIONALITY: 'fabric.register.fullname_nationality',
  FABRIC_REGISTER_ALERT: 'fabric.register.alert',
  FABRIC_REGISTER_ADDRESS: 'fabric.register.address',
});

export const PAYMENT_METHOD_TYPES = Object.freeze({
  TRUSTLY: 'TRUSTLY',
  SWISH: 'SWISH',
  SWISH_VIA_TRUSTLY: 'SWISH_VIA_TRUSTLY',
});

export const PAYMENT_METHOD_TITLES = Object.freeze({
  TRUSTLY: 'TRUSTLY',
  SWISH: 'SWISH',
  SWISH_VIA_TRUSTLY: 'SWISH',
});

export const GOOGLE_MAPS_ERRORS_MESSAGE = Object.freeze({
  INVALID_REQUEST: 'This request was invalid',
  NOT_FOUND: 'The place referenced was not found',
  OK: 'The response contains a valid result',
  OVER_QUERY_LIMIT: 'The application has gone over its request quota',
  REQUEST_DENIED: 'The application is not allowed to use the PlacesService',
  UNKNOWN_ERROR:
    'The PlacesService request could not be processed due to a server error.',
  ZERO_RESULTS: 'No result was found for this request',
});

export const GTM_ERRORS = Object.freeze({
  PROVIDER_NOT_RESPONDING: 'PROVIDER_NOT_RESPONDING',
  NO_ADDRESS: 'NO_ADDRESS',
  INVALID_EMAIL: 'INVALID_EMAIL',
  INVALID_PASSWORD: 'INVALID_PASSWORD',
  EMAIL_TAKEN: 'EMAIL_TAKEN',
  INVALID_PHONE: 'INVALID_PHONE',
  PHONE_TAKEN: 'PHONE_TAKEN',
  ALREADY_HAS_AN_ACCOUNT: 'ALREADY_HAS_AN_ACCOUNT',
  PLAYER_CLICKED_DEPOSIT_LIMITS: 'PLAYER_CLICKED_DEPOSIT_LIMITS',
  UNDER_AGE: 'UNDER_AGE',
  OVER_AGE: 'OVER_AGE',
  INVALID_BIRTH_DATE: 'INVALID_BIRTH_DATE',
  INVALID_POSTCODE: 'INVALID_POSTCODE',
});

export const GTM_ACTIONS = Object.freeze({
  FAIL: 'fail',
  ADDRESS: 'address',
  NATIONALITY: 'nationality',
  SHOW_NATIONALITY_DRAWER: 'show_nationality_drawer',
  WELCOME_OFFER: 'welcome_offer',
  SHOW_BONUS_TERMS: 'show_bonus_terms',
  SHOW_PRIVACY_POLICY: 'show_privacy_policy',
  SHOW_COOKIES_POLICY: 'show_cookies_policy',
  SHOW_TERMS: 'show_terms',
  DEPOSIT_AMOUNT_CLICK: 'deposit_amount_click',
  DEPOSIT: 'deposit',
  ALERT: 'alert',
  LIMIT_UPDATE: 'deposit_limit_update',
  LIMITS: 'limits',
  BIRTH_DETAILS: 'birth_details',
  MARKETING_CONSENT: 'marketing_consent',
  ACCOUNT_DETAILS: 'account_details',
  TIME_SPENT: 'time_spent',
  REGISTRATION_START: 'registration_start',
  REGISTRATION_DONE: 'registration_done',
  REGISTRATION_FAILED: 'registration_failed_callback',
  REGISTRATION_CLOSED: 'registration_closed',
  COUNTRY_OF_RESIDENCE: 'country_residence',
  SHOW_COUNTRY_OF_RESIDENCE_DRAWER: 'show_country_residence_drawer',
  GO_TO_LOGIN: 'go_to_login',
  PLACES_API_REQUEST_SENT: 'places_api_request_sent',
  PLACES_API_RESPONSE_RECEIVED: 'places_api_response_received',
  PLACES_SELECTED_ADDRESS: 'places_selected_address',
});

export const GTM_FEATURES = Object.freeze({
  REGISTER: 'register',
});

export const GTM_BASE = Object.freeze({
  TOPIC: 'analytics',
  KIND: 'gtm',
});

export const GTM_PAYLOAD = Object.freeze({
  YES: 'yes',
  NO: 'no',
  TRUE: 'true',
  FALSE: 'false',
  SELECT_WELCOME_OFFER: 'select_welcome_offer',
  DEPOSIT: 'deposit',
  LIMIT_STEP: 'limit',
  DEPOSIT_TOO_HIGH: 'deposit_too_high',
  DEPOSIT_TOO_LOW: 'deposit_too_low',
  DAILY: 'daily',
  WEEKLY: 'weekly',
  MONTHLY: 'monthly',
  NEAR_BY_SEARCH: 'near_by_search',
  DEPOSIT_REQUIRED: 'deposit_required',
});

export const GTM_PARAMS = Object.freeze({
  DEPOSIT_LIMIT_DEFINED: 'deposit_limit_defined',
});

export const COOKIE_NAMES = Object.freeze({
  TIME_SPENT: 'time_spent',
  FIRST_LOGIN: 'first_login',
  NATIVE_EVENTS_COOKIE: "native_events_cookie",
  IS_NATIVE_COOKIE: 'is_native_cookie',
  KAZUMO_TRACKING_ID: 'kazumoTrackingId',
  NATIVE_PAY_AND_PLAY: 'native_pay_and_play',
  KAZUMOKEYCLOAK: 'kazumoKeycloak',
  REGISTRATIONFORM: 'registrationform',
  ERROR_NAME: "error_name"
});

export const DEFAULT_ERROR = Object.freeze({
  errorName: 'unknown_error',
  title: 'Unknown error',
  message:
    'Please double-check your input and try again or contact support for help.',
  buttonText: 'Try Again',
  buttonSecondaryText: 'Contact support',
});

export const KEYCLOAK_ADDRESS_KEYS = Object.freeze([
  'address_street_name',
  'address_postal_code',
  'address_country',
  'address_city',
  'nationality',
]);

export const CASHIER_URL_PARAMS = Object.freeze({
  AMOUNT: 'amount',
  PAYMENT_METHOD_ID: 'paymentMethodId',
  PAY_AND_PLAY: 'payAndPlay',
  PAY_AND_PLAY_ID: 'payAndPlayId',
});

export const ROUTE_IDS = Object.freeze({
  ABOUT: 'ABOUT',
  FAQ: 'FAQ',
  NAVIGATE: 'NAVIGATE',
  SPORTS: 'SPORTS',
  GAMES_PLAY: 'GAMES_PLAY',
  PLAY_OKAY: 'PLAY_OKAY',
  PLAYER_ACCOUNT: 'PLAYER_ACCOUNT',
  PLAYER_PROFILE: 'PLAYER_PROFILE',
  PLAYER_INVENTORY: 'PLAYER_INVENTORY',
  PLAYER_LIMITS: 'PLAYER_LIMITS',
  PLAYER_LIMITS_EDIT_LIMIT: 'PLAYER_LIMITS_EDIT_LIMIT',
  PLAYER_LIMITS_EDIT_EXCLUSION: 'PLAYER_LIMITS_EDIT_EXCLUSION',
  PLAYER_NOTIFICATIONS: 'PLAYER_NOTIFICATIONS',
  PLAYER_SETTINGS: 'PLAYER_SETTINGS',
  PLAYER_VERIFICATION: 'PLAYER_VERIFICATION',
  CASH_DEPOSIT: 'CASH_DEPOSIT',
  CASH_WITHDRAWAL: 'CASH_WITHDRAWAL',
  CASH_TRANSACTIONS: 'CASH_TRANSACTIONS',
  CASH_BETS: 'CASH_BETS',
  GAMES_HUB: 'GAMES_HUB',
  GAMES_SEARCH: 'GAMES_SEARCH',
  GAMES_ROOT: 'GAMES_ROOT',
  PAYMENTS_REDIRECTION_CALLBACK: 'PAYMENTS_REDIRECTION_CALLBACK',
  REGION: 'REGION',
  PAYMENTS_PAY_AND_PLAY: 'PAYMENTS_PAY_AND_PLAY',
});
export const OPTED_OUT_WELCOME_OFFER_ID = 'opted-out-wo-registration';

export const REGILY_COUNTRY = {
  [MARKETS.EN]: ['en'], // this one is not set as country (it uses geo location inside regily)
  [MARKETS.FI]: ['fi'],
  [MARKETS.ENGB]: ['gb'],
  [MARKETS.DE]: ['de'],
  [MARKETS.ENCA]: ['ca'],
  [MARKETS.SV]: ['se'],
  [MARKETS.ENIN]: ['in'],
  [MARKETS.ENNZ]: ['nz'],
  [MARKETS.IE]: ['ie', 'no'],
  [MARKETS.ES]: ['es'],
  [MARKETS.JP]: ['jp'],
  [MARKETS.ON]: ['on'],
  /* NEW_MARKET https://casumo.regily.com/playground.html - dont add hidden markets here! */
};

export const REGILY_COUNTRY_REV = Object.fromEntries(
  Object.entries(REGILY_COUNTRY).flatMap(([market, countries]) =>
    countries.map((country) => [country, market])
  )
);

export const REGILY_REMAINING_COUNTRIES_MAP = Object.freeze({
  ph: MARKETS.ROW,
  id: MARKETS.ROW,
  dz: MARKETS.ROG,
  eg: MARKETS.ROW,
  lb: MARKETS.ROG,
  qa: MARKETS.ROG,
  ae: MARKETS.ROG,
  kw: MARKETS.ROG,
  vn: MARKETS.ROW,
  sa: MARKETS.ROG,
  sb: MARKETS.ROW,
  th: MARKETS.ROW,
  bq: MARKETS.ROW,
  cr: MARKETS.ROW,
  na: MARKETS.ROW,
  bm: MARKETS.ROW,
  bo: MARKETS.ROW,
  kn: MARKETS.ROW,
  bb: MARKETS.ROW,
  pe: MARKETS.ROW,
  za: MARKETS.ROW,
  uy: MARKETS.ROW,
  rw: MARKETS.ROW,
  mv: MARKETS.ROW,
  pg: MARKETS.ROW,
  cu: MARKETS.ROW,
  ls: MARKETS.ROW,
  ai: MARKETS.ROW,
  gt: MARKETS.ROW,
  tl: MARKETS.ROW,
  cl: MARKETS.ROW,
  my: MARKETS.ROW,
  tz: MARKETS.ROW,
  tc: MARKETS.ROW,
  fj: MARKETS.ROW,
  kp: MARKETS.ROW,
  tt: MARKETS.ROW,
  lc: MARKETS.ROW,
  nr: MARKETS.ROW,
  cw: MARKETS.ROW,
  md: MARKETS.ROW,
  sc: MARKETS.ROW,
  sh: MARKETS.ROW,
  nu: MARKETS.ROW,
  io: MARKETS.ROW,
  cv: MARKETS.ROW,
  gd: MARKETS.ROW,
  hn: MARKETS.ROW,
  sz: MARKETS.ROW,
  do: MARKETS.ROW,
  gw: MARKETS.ROW,
  mz: MARKETS.ROW,
  ir: MARKETS.ROW,
  mh: MARKETS.ROW,
  ml: MARKETS.ROW,
  mk: MARKETS.ROW,
  sx: MARKETS.ROW,
  ru: MARKETS.ROW,
  mw: MARKETS.ROW,
  tk: MARKETS.ROW,
  gq: MARKETS.ROW,
  tv: MARKETS.ROW,
  mg: MARKETS.ROW,
  aw: MARKETS.ROW,
  kr: MARKETS.ROW,
  jm: MARKETS.ROW,
  la: MARKETS.ROW,
  ms: MARKETS.ROW,
  fk: MARKETS.ROW,
  br: MARKETS.ROW,
  bw: MARKETS.ROW,
  pn: MARKETS.ROW,
  vc: MARKETS.ROW,
  am: MARKETS.ROW,
  st: MARKETS.ROW,
  vg: MARKETS.ROW,
  ci: MARKETS.ROW,
  ge: MARKETS.ROW,
  mu: MARKETS.ROW,
  gs: MARKETS.ROW,
  fm: MARKETS.ROW,
  mx: MARKETS.ROW,
  zm: MARKETS.ROW,
  ky: MARKETS.ROW,
  to: MARKETS.ROW,
  ki: MARKETS.ROW,
  cc: MARKETS.ROW,
  ar: MARKETS.ROW,
  mn: MARKETS.ROW,
  /* NEW_MARKET */
});

export const ADDRESS_COMPONENTS = Object.freeze({
  CITY: 'city',
  POSTAL_CODE: 'postalCode',
  STREET_NAME: 'streetName',
  HOUSE_NUMBER: 'houseNumber',
  MANUAL_ADDRESS: 'manual',
  AUTOMATED_ADDRESS: 'automated',
});

const DATA_TEST_ID_PREFIX = 'registration-';

export const TEST_ID = Object.freeze({
  PREFIX: DATA_TEST_ID_PREFIX,
  SELECT: `${DATA_TEST_ID_PREFIX}select-`,
  TITLE: `${DATA_TEST_ID_PREFIX}title-`,
  DESCRIPTION: `${DATA_TEST_ID_PREFIX}description`,
  SEARCH: `${DATA_TEST_ID_PREFIX}search-`,
  INPUT: `${DATA_TEST_ID_PREFIX}input-`,
  BUTTON: `${DATA_TEST_ID_PREFIX}button-`,
  TOGGLE: `${DATA_TEST_ID_PREFIX}toggle-`,
  COMBOBOX_TRIGGER: `${DATA_TEST_ID_PREFIX}combobox-trigger-`,
  COUNTRY: `${DATA_TEST_ID_PREFIX}country-`,
  PROVINCE: `${DATA_TEST_ID_PREFIX}province-`,
  BUTTON_PRIMARY: `${DATA_TEST_ID_PREFIX}button-primary`,
  BUTTON_SECONDARY: `${DATA_TEST_ID_PREFIX}button-secondary`,
  BUTTON_NO_BONUS: `${DATA_TEST_ID_PREFIX}button-no-bonus`,
  BUTTON_SAVE_REGISTER: `${DATA_TEST_ID_PREFIX}button-save-register`,
  BUTTON_ACCEPT_ALL_REGISTER: `${DATA_TEST_ID_PREFIX}button-accept-all-register`,
  PHONE_PREFIX: `${DATA_TEST_ID_PREFIX}phone-prefix-`,
  SEARCH_RESULT: `${DATA_TEST_ID_PREFIX}search-result-`,
  CHECKBOX: `${DATA_TEST_ID_PREFIX}checkbox-`,
  TEXT: `${DATA_TEST_ID_PREFIX}text`,
  DETAILS: `${DATA_TEST_ID_PREFIX}details`,
  WELCOME_OFFER_ARROW: `${DATA_TEST_ID_PREFIX}welcome-offer-arrow`,
  WELCOME_OFFER_DOT: `${DATA_TEST_ID_PREFIX}welcome-offer-dot`,
});

export const NOTIFICATIONS_CHANNELS = 'sms,email,phone'
export const FULLSTORY_MASK_CLASSNAME = 'fs-mask';

export const REGISTRATION_MODAL_SESSION_STORAGE_KEY = 'fabric:registration:open';

export const MARKETS_CODES = {
  ___en: '___en',
  ca_en: 'ca_en',
  fi_fi: 'fi_fi',
  gb_en: 'gb_en',
  in_en: 'in_en',
  no_no: 'no_no',
  on_en: 'on_en',
  se_sv: 'se_sv',
  es_es: 'es_es',
  nz_en: 'nz_en',
  jp_ja: 'jp_ja',
  ie_en: 'ie_en',
  de_de: 'de_de',
  at_de: 'at_de',
} as const;

export const MARKET_SLUG_TO_CODE = {
  en: MARKETS_CODES.___en,
  'en-ca': MARKETS_CODES.ca_en,
  on: MARKETS_CODES.on_en,
  no: MARKETS_CODES.no_no,
  fi: MARKETS_CODES.fi_fi,
  'en-gb': MARKETS_CODES.gb_en,
  'en-in': MARKETS_CODES.in_en,
  sv: MARKETS_CODES.se_sv,
  es: MARKETS_CODES.es_es,
  'en-nz': MARKETS_CODES.nz_en,
  ja: MARKETS_CODES.jp_ja,
  ie: MARKETS_CODES.ie_en,
  row: MARKETS_CODES.___en,
  rog: MARKETS_CODES.___en,
} as const;