export type TCacheOptions = Partial<{
  ttl: number
  tags: string[]
}>

type TInvalidateOptions = {
  invalidateAll(): void
  invalidateByKey(key: string): void
  invalidateByTag(tag: string): void
}

export type TFetchCache = {
  set(key: string, promise: Promise<Response>, options: TCacheOptions): Promise<void>
  get(key: string): Response | undefined
  has(key: string): boolean
} & TInvalidateOptions

export type TCache = {
  getCache(): TFetchCache
} & TInvalidateOptions

export const CACHE_KEY_HEADER = 'x-fabric-cache-key'
export const CACHE_TTL_HEADER = 'x-fabric-cache-ttl'
export const CACHE_TAGS_HEADER = 'x-fabric-cache-tags'

export type TCacheHeaders =
  | typeof CACHE_KEY_HEADER
  | typeof CACHE_TTL_HEADER
  | typeof CACHE_TAGS_HEADER
