import { useEffect, useState } from 'react';
import {
  UseDirectusContext,
  UseRegistrationContext,
  useHeader,
  useDrawer,
} from '../../../contexts';
import {
  ROUTE_NAMES,
  BIRTH_TYPES,
  REGISTRATION_ERRORS,
  GTM_FEATURES,
  GTM_ACTIONS,
  GTM_ERRORS,
  REGISTRATION_FLOW_STEPS,
} from '../../../constants';
import {
  storeDataInLocalStorage,
  getErrorMessage,
  getStepNumberTranslation,
  getBonusMessage,
  getDateFormat,
  getCountryCodeFromCountryName,
} from '../../../utilities';
import {
  birthDateValidationResult,
  isValidateField,
} from '../../../validations';
import {
  TErrorMessage,
  TRegistrationResponse,
  TUserBirthDetails,
} from '../../../typedefs';
import { parse } from 'date-fns';
import {
  sendGtmEvent,
  setAlertGtmEvent,
  setNationalitySelectorGtmEvent,
} from '../../../gtm';
import { getErrorData } from '../../../service/directus/errors';
import { BirthDetailsPage } from 'Pages/birthDetailsPage';
import { getBirthDetailsPageData } from 'Src/service/directus/birthDetails';
import { WO_VERTICALS } from 'Components/valuableThumbnail/valuableThumbnailConstants';
import { UseHandleKeyDown } from 'Src/hooks/handleKeyDown';
import { gatekeeper } from 'Src/gatekeeper';
import { adaptCountryFlags } from 'Src/service/directus/countryFlags/adapters';

export const BirthDetailsContainer = () => {
  const {
    routeName,
    userValues,
    setRouteName,
    setUserValues,
    setPreviousRouteName,
  } = UseRegistrationContext();
  const birthDetails = userValues?.birthDetails;

  const { setHeaderTitle } = useHeader();
  const {
    isDrawerOpen,
    isFlagDrawerOpen,
    isMessageDrawerOpen,
    toggleFlagDrawer,
  } = useDrawer();
  const { registrationFlowsData, errorData, countryFlagsData } =
    UseDirectusContext();
  const allErrorData = getErrorData(errorData);
  const birthDetailsPageData = getBirthDetailsPageData(registrationFlowsData);
  const [birthDate, setBirthDate] = useState<string | null>(null);
  const [birthDateError, setBirthDateError] = useState<string | null>(null);
  const [placeOfBirth, setPlaceOfBirth] = useState<string | null>(null);
  const [placeOfBirthError, setPlaceOfBirthError] = useState<string | null>(
    null
  );
  const [description, setDescription] = useState<string | null>(null);
  const [isUserSelectedCountryManually, setisUserSelectedCountryManually] =
    useState(false);

  const stepNumber =
    getStepNumberTranslation('4', '4')[gatekeeper.localisation.marketSlug] ||
    getStepNumberTranslation('4', '4')?.default;

  const adaptedCountryFlags = adaptCountryFlags(countryFlagsData);
  const [countryOfBirthError, setCountryOfBirthError] = useState<string | null>(
    null
  );
  const [countryOfBirth, setCountryOfBirth] = useState<string | null>(null);
  const [countryOfBirthCode, setCountryOfBirthCode] = useState<string | null>(
    null
  );
  const [selectedFlagUuid, setSelectedFlagUuid] = useState<string | null>(null);
  const [enableNationalitySelector, setEnableNationalitySelector] =
    useState<boolean>(false);
  const [searchValue, setSearchValue] = useState<string>('');

  const selectedNationality = (
    nationality = userValues?.countryOfResidence
  ) => {
    return adaptedCountryFlags.filter(
      (country) => country.name === nationality
      //Once we add more markets this needs to be updated to set the correct default market
    );
  };

  useEffect(() => {
    setPreviousRouteName(ROUTE_NAMES.NATIONALITY);
    storeDataInLocalStorage(ROUTE_NAMES.BIRTH_DETAILS, userValues);
    setHeaderTitle(birthDetailsPageData?.headerTitle);
    // Read stored data and set it if there is any.
    if (birthDetails && Object.keys(birthDetails).length > 0) {
      setBirthDate(birthDetails?.birthDate);
      setPlaceOfBirth(birthDetails?.placeOfBirth?.city);
    }
    const cachedCountryOfBirth =
      userValues?.countryOfBirth || userValues.nationality;
    if (userValues.isUserSelectedCountryManually) {
      setisUserSelectedCountryManually(
        userValues.isUserSelectedCountryManually
      );
      setCountryOfBirth(cachedCountryOfBirth);
      setCountryOfBirthCode(
        userValues?.countryOfBirthCode ||
          getCountryCodeFromCountryName(cachedCountryOfBirth)
      );
      setSelectedFlagUuid(selectedNationality(cachedCountryOfBirth)[0]?.uuid);
      return;
    }

    if (cachedCountryOfBirth) {
      const currentSelectedCountry =
        cachedCountryOfBirth === userValues.nationality
          ? cachedCountryOfBirth
          : userValues.nationality;
      setCountryOfBirth(currentSelectedCountry);
      setCountryOfBirthCode(
        userValues?.countryOfBirthCode ||
          getCountryCodeFromCountryName(currentSelectedCountry)
      );
      setSelectedFlagUuid(selectedNationality(currentSelectedCountry)[0]?.uuid);
    } else {
      const countryOfResidence = selectedNationality()[0];
      setCountryOfBirth(countryOfResidence?.name);
      setCountryOfBirthCode(
        getCountryCodeFromCountryName(countryOfResidence?.name)
      );
      setSelectedFlagUuid(countryOfResidence?.uuid);
    }
  }, []);
  useEffect(() => {
    if (
      userValues?.selectedWelcomeOffer?.vertical === WO_VERTICALS.SPORTS ||
      !userValues.depositAmount
    ) {
      setDescription(userValues?.selectedWelcomeOffer?.shortDesc);
    } else {
      setDescription(
        getBonusMessage(
          birthDetailsPageData?.woMessage,
          userValues.depositAmount || 0,
          userValues.bonusAmount || 0,
          userValues.currency
        )
      );
    }
  }, [birthDetailsPageData]);

  useEffect(() => {
    if (isFlagDrawerOpen)
      setNationalitySelectorGtmEvent(REGISTRATION_FLOW_STEPS.NATIONALITY_STEP);
  }, [isFlagDrawerOpen]);

  const changeValue = (value: string, inputType: string) => {
    if (inputType === BIRTH_TYPES.BIRTH_DATE) {
      setBirthDateError(null);
      setBirthDate(value);
    }
    if (inputType === BIRTH_TYPES.PLACE_OF_BIRTH) {
      setPlaceOfBirthError(null);
      setPlaceOfBirth(value);
    }
    if (inputType === BIRTH_TYPES.SEARCH) setSearchValue(value);
  };

  const clearInput = (inputType: string) => {
    if (inputType === BIRTH_TYPES.BIRTH_DATE) {
      setBirthDateError(null);
      setBirthDate(null);
    }
    if (inputType === BIRTH_TYPES.PLACE_OF_BIRTH) {
      setPlaceOfBirthError(null);
      setPlaceOfBirth(null);
    }
    if (inputType === BIRTH_TYPES.SEARCH) setSearchValue(null);
  };

  const birthDateValidation = async (onContinue: boolean) => {
    if (!birthDate && !onContinue) {
      setBirthDateError(null);
      return false;
    }
    const parsedDate = birthDate
      ? parse(birthDate, getDateFormat(), new Date())
      : null;
    const validationResult: TRegistrationResponse =
      await birthDateValidationResult(
        parsedDate,
        userValues.countryOfResidenceCountryCode
      );
    if (!validationResult?.success) {
      const message: TErrorMessage = getErrorMessage(
        allErrorData,
        validationResult?.error
      );
      setBirthDateError(message?.message);
      return false;
    }
    setBirthDateError(null);
    return true;
  };

  const birthPlaceValidation = async (onContinue: boolean) => {
    if (!placeOfBirth && !onContinue) {
      setPlaceOfBirthError(null);
      return false;
    }
    const validationResult: TRegistrationResponse = isValidateField(
      placeOfBirth,
      REGISTRATION_ERRORS.BIRTH_PLACE_IS_REQUIRED
    );
    if (!validationResult.success) {
      const message: TErrorMessage = getErrorMessage(
        allErrorData,
        validationResult?.error
      );
      setPlaceOfBirthError(message.message);
      return false;
    }
    setPlaceOfBirthError(null);
    return true;
  };

  const redirectToDepositLimit = () => {
    onConfirm(ROUTE_NAMES.LIMITS);
    setAlertGtmEvent({
      alert: GTM_ERRORS.PLAYER_CLICKED_DEPOSIT_LIMITS,
      step: REGISTRATION_FLOW_STEPS.BIRTH_DETAILS_STEP,
    });
  };

  const onConfirm = async (path: string) => {
    let onContinue = true;
    const isDateCorrect = await birthDateValidation(onContinue);
    const isPlaceCorrect = await birthPlaceValidation(onContinue);

    
    if (isDateCorrect && isPlaceCorrect) {
      sendGtmEvent(GTM_FEATURES.REGISTER, GTM_ACTIONS.BIRTH_DETAILS, {
        step: GTM_ACTIONS.BIRTH_DETAILS,
        country: countryOfBirthCode,
      });
      const birthDetails: TUserBirthDetails = {
        birthDate: birthDate,
        placeOfBirth: {
          city: placeOfBirth,
          country: countryOfBirthCode,
        },
      };
      setUserValues({
        ...userValues,
        birthDetails,
        countryOfBirth,
        isUserSelectedCountryManually,
        countryOfBirthCode,
      });
      storeDataInLocalStorage(ROUTE_NAMES.BIRTH_DETAILS, {
        ...userValues,
        birthDetails,
        countryOfBirth,
        isUserSelectedCountryManually,
        countryOfBirthCode,
      });
      setRouteName(path);
    }
  };

  const dependencyArray = [
    isDrawerOpen,
    isFlagDrawerOpen,
    isMessageDrawerOpen,
    onConfirm,
    birthDate,
    placeOfBirth,
  ];
  UseHandleKeyDown(
    dependencyArray,
    !isDrawerOpen && !isFlagDrawerOpen && !isMessageDrawerOpen,
    () => onConfirm(ROUTE_NAMES.MARKETING_CONSENT)
  );

  const sendTermsGtmEvent = (gtmAction: string) => {
    sendGtmEvent(GTM_FEATURES.REGISTER, gtmAction, {
      step: GTM_ACTIONS.BIRTH_DETAILS,
    });
  };

  if (routeName !== ROUTE_NAMES.BIRTH_DETAILS) {
    return null;
  }
  const changeNationality = (countryName: string) => {
    if (!countryName) return;

    const nationality = countryName ?? selectedNationality()[0].name;
    if (countryName) {
      const countryOfBirthCodeValue =
        getCountryCodeFromCountryName(nationality);
      const currentData = {
        ...userValues,
        countryOfBirthCode: countryOfBirthCodeValue,
        countryOfBirth: nationality,
        isUserSelectedCountryManually: true,
      };
      setisUserSelectedCountryManually(true);
      setUserValues(currentData);
      storeDataInLocalStorage(ROUTE_NAMES.BIRTH_DETAILS, currentData);
      setCountryOfBirth(nationality);
      setCountryOfBirthCode(countryOfBirthCodeValue);
      setSelectedFlagUuid(selectedNationality(nationality)[0]?.uuid);
      setCountryOfBirthError(null);
    }
    toggleFlagDrawer();
  };
  const handleOpenCountrySelector = () => {
    setEnableNationalitySelector(true);
    toggleFlagDrawer();
  };
  return (
    <BirthDetailsPage
      userValues={userValues}
      description={description}
      birthDetailsPageData={birthDetailsPageData}
      changeValue={changeValue}
      clearInput={clearInput}
      birthDate={birthDate}
      placeOfBirth={placeOfBirth}
      birthDateError={birthDateError}
      placeOfBirthError={placeOfBirthError}
      validateBirthDate={birthDateValidation}
      validateBirthPlace={birthPlaceValidation}
      onConfirm={onConfirm}
      sendTermsGtmEvent={sendTermsGtmEvent}
      redirectToDepositLimit={redirectToDepositLimit}
      stepNumber={stepNumber}
      dateFormat={getDateFormat()}
      countryOfBirthError={countryOfBirthError}
      selectedFlagUuid={selectedFlagUuid}
      countryOfBirth={countryOfBirth}
      openCountrySelector={handleOpenCountrySelector}
      countryFlagsData={adaptedCountryFlags}
      enableDrawer={enableNationalitySelector}
      isDrawerOpen={isFlagDrawerOpen}
      setIsDrawerOpen={toggleFlagDrawer}
      searchValue={searchValue}
      setCountryOfBirth={changeNationality}
    />
  );
};
