import { useState } from 'react';
import { UseRegistrationContext, UseTimerContext } from '../../contexts';
import {
  ADDRESS_COMPONENTS,
  BE_FE_ERRORS_MAP,
  COOKIE_NAMES,
  GTM_ACTIONS,
  MARKETS,
  OPTED_OUT_WELCOME_OFFER_ID,
  REGISTRATION_ERRORS,
  REGISTRATION_MODAL_SESSION_STORAGE_KEY,
} from '../../constants';
import {
  getCountryCodeFromCountryName,
  loginUserAndRedirectToPayment,
} from '../../utilities';
import { registerUser } from '../../service';
import { setRegistrationCompleteGtmEvent } from '../../gtm';
import { storeInCookie } from '../../cookies';
import { IRegisterUserData } from '../../service/registration/adapters';
import { TRegistrationDoneGTM } from 'Src/typedefs';
import { gatekeeper } from 'Src/gatekeeper';

const useInitiateRegistration = () => {
  const { userValues, setRegistrationError, isNative, setRegistrationErrorData } =
    UseRegistrationContext();
  const { calculateTimeSpent } = UseTimerContext();
  const [isLoader, setIsLoader] = useState<boolean>(false);

  const registrationCompleteTracking = (
    registrationPayload: IRegisterUserData,
    market: string
  ): TRegistrationDoneGTM => {
    const timer = calculateTimeSpent() || null;
    return {
      email: userValues?.email,
      lang: gatekeeper?.localisation?.localeCode,
      address_country:
        userValues?.address?.country || registrationPayload?.address.country,
      address_city:
        userValues?.address?.city || registrationPayload?.address.city,
      address_postal_code:
        userValues?.address?.postalCode ||
        registrationPayload?.address.postalCode,
      address_street_name:
        userValues?.address?.streetName ||
        registrationPayload?.address.streetName,
      address_house_number:
        userValues?.address?.houseNumber ||
        registrationPayload?.address.houseNumber,
      day_of_birth: registrationPayload?.dayOfBirth,
      month_of_birth: registrationPayload?.monthOfBirth,
      year_of_birth: registrationPayload?.yearOfBirth,
      place_of_birth: {
        city: userValues?.birthDetails?.placeOfBirth?.city || undefined,
        country: undefined, // This should be changed in the future with the real country
      },
      birth_details_gender:
        userValues?.gender || registrationPayload?.gender,
      nationality: getCountryCodeFromCountryName(userValues?.nationality),
      country_residence:
        userValues?.countryOfResidence || registrationPayload?.address.country,
      country_residence_country_code:
        userValues?.countryOfResidenceCountryCode ||
        registrationPayload?.address.country,
      ...(market === MARKETS.SV
        ? {
          deposit_amount: userValues?.depositAmount,
          deposit_defined: !!userValues?.depositAmount,
          daily_limit: userValues?.depositLimits?.daily,
          weekly_limit: userValues?.depositLimits?.weekly,
          monthly_limit: userValues?.depositLimits?.monthly,
        }
        : {
          deposit_limit: userValues?.depositLimits?.depositLimit,
          deposit_type: userValues?.depositLimits?.depositType,
        }),
      phone_country_prefix: userValues?.phoneNumber?.country?.countryCode,
      phone_number: userValues?.phoneNumber?.number,
      time_spent: timer?.timeSpent,
      number_of_occasions: timer?.numberOfOccasions,
      country_redirect: userValues?.countryRedirect || false,
      wo_used:
        userValues?.selectedWelcomeOffer?.name !== OPTED_OUT_WELCOME_OFFER_ID,
      wo_id: userValues?.selectedWelcomeOffer?.name,
      welcome_offer_vertical: userValues?.selectedWelcomeOffer?.vertical,
      residential_address: userValues?.residentialAddress
        ? ADDRESS_COMPONENTS.MANUAL_ADDRESS
        : ADDRESS_COMPONENTS.AUTOMATED_ADDRESS,
      marketing_consent: registrationPayload?.subscribeToMarketingMaterial,
      kazumoTtrackingId: registrationPayload?.trackingId,
    };
  };

  const initiateRegistration = async (
    values: IRegisterUserData,
    pathUrl: string,
    market: string
  ) => {
    if (!values) {
      return;
    }
    const registrationTrackingData: TRegistrationDoneGTM =
      registrationCompleteTracking(
        {
          ...values,
        },
        market
      );

    try {
      const registrationResponse = await registerUser(values, pathUrl);

      if (!registrationResponse?.success) {
        console?.error(
          'registrationResponse status:',
          registrationResponse?.success
        );
        setRegistrationCompleteGtmEvent(
          {
            ...registrationTrackingData,
            status_code: registrationResponse?.statusCode,
            failed_attempts: registrationResponse?.failedAttempts,
          },
          GTM_ACTIONS.REGISTRATION_FAILED
        );
        if (registrationResponse?.errorData) {
          setRegistrationErrorData(registrationResponse?.errorData)
        }
        setRegistrationError(registrationResponse?.error);
        return;
      }
      setRegistrationCompleteGtmEvent(
        {
          ...registrationTrackingData,
          userId: registrationResponse?.data?.playerId,
          status_code: registrationResponse?.statusCode,
          failed_attempts: registrationResponse?.failedAttempts,
        },
        GTM_ACTIONS.REGISTRATION_DONE
      );

      storeInCookie(COOKIE_NAMES.FIRST_LOGIN, 'first_login', 'true');

      if (market?.toLowerCase() === 'sv') {
        userValues?.paymentMethodId
          ? await loginUserAndRedirectToPayment(
            {
              ...userValues,
              payAndPlayId: registrationResponse?.data?.payAndPlayId,
            },
            isNative
          )
          : await gatekeeper.auth.logIn({});
        return;
      }

      window.location.href = '/';
      sessionStorage.removeItem(REGISTRATION_MODAL_SESSION_STORAGE_KEY);
    } catch (error) {
      console.error('initiateRegistration error:', error);
      const errorCodeBE = error?.message;
      const errorCodeFE =
        BE_FE_ERRORS_MAP[errorCodeBE] || REGISTRATION_ERRORS.UNKNOWN;
      setRegistrationError(errorCodeFE);
    }
  };

  return { initiateRegistration, isLoader, setIsLoader };
};

export default useInitiateRegistration;
