import { useEffect } from 'react';
import { gatekeeper } from './gatekeeper';
import { COOKIE_NAMES, MARKETS, REGISTRATION_ERRORS, REGISTRATION_MODAL_SESSION_STORAGE_KEY } from './constants';
import {
  RegistrationContextProvider,
  DevcodeUserDataContextProvider,
  DirectusContextProvider,
  TimerContextProvider,
  HeaderContextProvider,
  UseRegistrationContext,
} from './contexts';
import { DrawerContextProvider } from './contexts/drawer-context';
import SwedishPlayNow from './markets/sweden/containers/index';
import MgaGraRegistration from './markets/mgaGra/containers/index';
import UkgcRegistration from './markets/ukgc/containers/index';
import { ModalRegistrationContextProvider, useRegistrationModal } from './contexts/modal-registration-context';
import { extractNativeEventDataFromCookie, getCookieByName, isRegistrationEnabled, setRegistrationStartNativeEvents } from './utilities';

const MarketFlow = () => {
  const { openModal } = useRegistrationModal();
  const { setRegistrationError } = UseRegistrationContext();

  useEffect(() => {
    if(
      (
        gatekeeper.navigation.isActiveRoute("/register") ||
        location.hash === "#signup" ||
        sessionStorage.getItem(REGISTRATION_MODAL_SESSION_STORAGE_KEY) === 'true'
      ) && isRegistrationEnabled()
    ) {
      openModal();
    }
    const unsubscribe = gatekeeper.messageBus.subscribe('registration', async ({ kind }) => {
      if (kind === 'start') {
        if (isRegistrationEnabled()) {
          sessionStorage.setItem(REGISTRATION_MODAL_SESSION_STORAGE_KEY, 'true');
          const tokenSet = await gatekeeper.auth.getAccessToken();
          const nativeEventsCookie = getCookieByName(COOKIE_NAMES.NATIVE_EVENTS_COOKIE);
          const isFirstLoginCookie = getCookieByName(COOKIE_NAMES.FIRST_LOGIN);
          const nativeEventsData = extractNativeEventDataFromCookie(nativeEventsCookie);

          const errorNameCookie = getCookieByName(COOKIE_NAMES.ERROR_NAME);

          if (errorNameCookie) {
            const error = Object.values(REGISTRATION_ERRORS)?.find(
              (item) =>
                item === errorNameCookie?.toLowerCase()
            ) || 'unknown_error';
            setRegistrationError(error);
          }

          if (!tokenSet &&
            !nativeEventsCookie &&
            !isFirstLoginCookie) {
            if (gatekeeper.localisation.marketSlug === MARKETS.SV) {
              setRegistrationStartNativeEvents()
              gatekeeper.auth.logIn({})
            }
          }
          openModal()
        }
      }
    });

    return () => {
      unsubscribe();
    };
  }, []);

  switch (gatekeeper.localisation.marketSlug) {
    case MARKETS.SV:
      return <SwedishPlayNow />;
    case MARKETS.ENGB:
      return <UkgcRegistration />;
    default:
      return <MgaGraRegistration />;
  }
};

export const App = () => {
  return (
    <ModalRegistrationContextProvider>
      <TimerContextProvider>
        <DirectusContextProvider>
          <RegistrationContextProvider>
            <DrawerContextProvider>
              <DevcodeUserDataContextProvider>
                <HeaderContextProvider>
                  <MarketFlow />
                </HeaderContextProvider>
              </DevcodeUserDataContextProvider>
            </DrawerContextProvider>
          </RegistrationContextProvider>
        </DirectusContextProvider>
      </TimerContextProvider>
    </ModalRegistrationContextProvider>
  );
};