{
  "name": "@casumo/fabric-registration",
  "version": "2.37.11",
  "description": "Registration flow repository",
  "source": "src/index.tsx",
  "browserslist": "> 0.5%, last 2 versions, not dead",
  "scripts": {
    "standalone": "cross-env STANDALONE=true parcel index.html --port=1234",
    "injected": "parcel src/index.tsx --port=1234",
    "build": "parcel build && npm run build-standalone && npm run build-version && npm run build-storybook",
    "build-standalone": "cp static/index.html dist/index.html",
    "build-version": "npm pkg get version > dist/version.html",
    "build-storybook": "build-storybook --output-dir dist/storybook",
    "test": "node --experimental-vm-modules node_modules/jest/bin/jest.js",
    "storybook": "start-storybook -p 6006",
    "lint": "prettier --plugin-search-dir . --check . && eslint .",
    "format": "prettier --plugin-search-dir . --write ."
  },
  "jest": {
    "extensionsToTreatAsEsm": [
      ".ts",
      ".tsx"
    ],
    "verbose": true,
    "preset": "ts-jest/presets/default-esm",
    "testEnvironment": "jsdom",
    "transform": {
      "^.+\\.(ts|tsx)?$": [
        "ts-jest",
        {
          "useESM": true
        }
      ]
    },
    "testPathIgnorePatterns": [
      "./dist"
    ],
    "transformIgnorePatterns": [
      "/node_modules/(?!storage-manager-js)"
    ],
    "testEnvironmentOptions": {
      "customExportConditions": []
    },
    "testRegex": "(/__tests__/.*|\\.(test|spec))\\.(ts|tsx|js)$",
    "moduleFileExtensions": [
      "ts",
      "tsx",
      "js",
      "json"
    ]
  },
  "publishConfig": {
    "registry": "https://npm.pkg.github.com"
  },
  "files": [
    "dist"
  ],
  "author": "Casumo",
  "license": "UNLICENSED",
  "devDependencies": {
    "@babel/core": "^7.20.12",
    "@babel/preset-env": "^7.22.7",
    "@casumo/fabric-components": "3.3.3",
    "@casumo/fabric-fundamentals": "^5.5.46",
    "@parcel/transformer-sass": "^2.8.3",
    "@storybook/addon-essentials": "^6.4.22",
    "@storybook/addon-postcss": "^2.0.0",
    "@storybook/builder-webpack5": "^6.5.16",
    "@storybook/manager-webpack5": "^6.5.16",
    "@storybook/react": "^6.1.21",
    "@storybook/testing-library": "^0.0.13",
    "@testing-library/react": "^14.0.0",
    "@types/chai": "^4.3.4",
    "@types/jest": "^29.5.2",
    "@types/mocha": "^10.0.1",
    "@types/node": "^18.11.18",
    "@types/react": "^18.0.28",
    "@types/react-dom": "^18.0.10",
    "@typescript-eslint/eslint-plugin": "^5.52.0",
    "@typescript-eslint/parser": "^5.52.0",
    "babel-jest": "^29.6.0",
    "babel-loader": "^8.3.0",
    "buffer": "^5.7.1",
    "chai": "^4.3.7",
    "cross-env": "^7.0.3",
    "eslint": "^8.34.0",
    "eslint-config-prettier": "^8.6.0",
    "eslint-config-react-app": "^7.0.1",
    "eslint-plugin-flowtype": "^8.0.3",
    "eslint-plugin-fp": "^2.3.0",
    "eslint-plugin-import": "^2.27.5",
    "eslint-plugin-prettier": "^4.2.1",
    "eslint-plugin-react": "^7.32.2",
    "eslint-plugin-react-hooks": "^4.6.0",
    "jest": "^29.6.0",
    "jest-environment-jsdom": "^29.6.0",
    "mocha": "^10.2.0",
    "parcel": "^2.7.0",
    "parcel-bundler": "^1.12.5",
    "postcss": "^8.4.16",
    "postcss-prefix-selector": "^2.1.0",
    "prettier": "^2.8.4",
    "process": "^0.11.10",
    "tailwindcss": "^3.1.8",
    "ts-jest": "^29.1.1",
    "ts-node": "^10.9.1",
    "typescript": "^4.9.5"
  },
  "dependencies": {
    "@types/google.maps": "^3.55.3",
    "axios": "^1.7.7",
    "classnames": "^2.3.2",
    "date-fns": "^3.6.0",
    "js-cookie": "^3.0.1",
    "postcode-validator": "^3.8.21",
    "react": "^18.2.0",
    "react-dom": "^18.2.0",
    "react-slick": "^0.30.2",
    "slick-carousel": "^1.8.1",
    "storage-manager-js": "^4.2.6",
    "validator": "^13.9.0"
  }
}
